[data-bs-toggle="tooltip"]:hover {
    cursor: pointer;
}

.tooltip {

    ul {
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;
        text-align: left;
    }
}

.popover {

    .popover-header {
        border-bottom: 0;
    }
}
