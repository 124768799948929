.table {
    margin: 0;

    td, th {
        white-space: nowrap;
    }

    tfoot {
        td, th {
            border-bottom-width: 0;
        }
    }
}
