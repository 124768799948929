.select2-container {
    display: block;
}

.select2-selection {
    height: 41px !important;
    border: 1px solid #dee2e6 !important;
}

.select2-selection__rendered,
.select2-selection--multiple {
    padding: 0 1rem !important;
    line-height: 41px !important;
}

.select2-selection--multiple {

    .select2-selection__rendered {
        display: inline-flex !important;
        max-width: 80% !important;
        margin-bottom: 0 !important;
        padding: 0 !important;
        float: left !important;
    }

    .select2-search--inline {
        float: left !important;
    }

    .select2-search__field {
        position: absolute !important;
        height: 41px !important;
        margin: 0 !important;
    }

    .select2-selection__choice {
        background-color: #f1f2f4 !important;
        border: 0 !important;
        line-height: 27px !important;
        vertical-align: top !important;
        box-shadow: var(--teamdesq-box-shadow-sm) !important;
    }

    .select2-selection__choice__remove {
        border-right: 0 !important;
    }
}

.select2-selection__arrow,
.select2-selection__clear {
    position: relative;
    top: 0 !important;
    right: 1rem !important;
    margin-top: 0 !important;
}

.select2-selection__arrow {
    height: 41px !important;
}

.select2-selection__clear {
    height: 39px !important;
}

.select2-selection__placeholder {
    color: #0c1b32 !important;
    opacity: .5;
}

.select2-results__option--highlighted {
    background-color: #3d8df5 !important;
}

.select2-results__option--load-more {
    text-align: center;
    background-color: #f8f9fa;
}

.btn {

    .select2-container {
        width: auto !important;

        @include media-breakpoint-up(md) {
            width: 125px !important;
        }
    }

    .select2-selection {
        background-color: transparent !important;
        border: none !important;
    }

    .select2-selection__rendered,
    .select2-selection--multiple {
        padding: 0 !important;
    }

    .select2-selection--multiple {

        .select2-selection__rendered {
            max-width: 70% !important;
        }

        .select2-search__field {

            &::placeholder {
                text-align: center;
            }
        }
    }

    .select2-selection__arrow,
    .select2-selection__clear {
        right: 0 !important;
        height: 41px !important;
    }
}
