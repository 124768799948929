.notification {
    padding: .75rem 3rem .75rem 1rem;
    white-space: normal;
    border-bottom: 1px solid #dee2e6;

    &.unread {
        border-left: 2px solid #3d8df5;

        span {
            font-weight: 700;
        }
    }
}
