.pagination-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    padding: $spacer * 1.5;
    margin-top: $spacer * 1.5;

    .pagination {
        margin-bottom: 0;
    }
}
