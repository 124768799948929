.card {
    overflow: hidden;
    min-height: 100%;

    &-header {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;
        background-color: transparent;

        @include media-breakpoint-up(md) {
            flex-direction: row;
            align-items: center;
        }

        .card-title {
            @include media-breakpoint-down(md) {
                margin-bottom: $spacer * 1.5;
            }
        }
    }

    .card-subtitle {
        font-size: $h5-font-size;
        margin-top: $spacer * .25;
    }
}
