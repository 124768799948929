.sidebar {
    padding-left: 0;
    padding-right: 0;

    @include media-breakpoint-up(lg) {
        min-height: 100vh;
        height: 100vh;
        position: relative;
    }

    .navbar {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        align-content: flex-start;
        padding: $spacer;

        @include media-breakpoint-up(lg) {
            max-width: 260px;
            padding: 0;
            height: 100%;
        }

        .navbar-brand {
            flex: 0 0 auto;
            width: auto;
            text-align: center;
            padding: 0;

            @include media-breakpoint-up(lg) {
                flex: 0 0 100%;
                width: 100%;
                padding-top: $spacer;
                margin-right: 0;
            }

            > img {
                max-height: 40px;
                max-width: 100%;
                height: auto;
                width: auto;

                @include media-breakpoint-up(lg) {
                    max-height: 72px;
                }
            }
        }

        .navbar-toggler {

            .navbar-toggler-icon {
                position: relative;
                display: block;
                width: 20px;
                height: 2px;
                border-radius: 1px;
                margin: 5px 0 5px 0;
                -webkit-transition: all .3s;
                transition: all .3s;
                background-color: $white;
                background-image: none;

                &:nth-of-type(2) {
                    left: 5px;
                }
            }

            &.navbar-toggler-close {
                padding: $spacer $spacer*1.25;

                .navbar-toggler-icon {

                    &:nth-of-type(1) {
                        transform: rotate(45deg);
                        transform-origin: 10% 10%;
                        top: 2px;
                        left: 3px;
                        width: 20px;
                    }

                    &:nth-of-type(2) {
                        opacity: 0;
                        filter: alpha(opacity = 0);
                    }

                    &:nth-of-type(3) {
                        transform: rotate(-45deg);
                        transform-origin: 10% 90%;
                        top: -1px;
                        left: 3px;
                    }
                }
            }
        }

        .offcanvas-header {
            padding: 0;
            margin-right: -$spacer*1.5;
            margin-left: -$spacer*1.5;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .form-scout-wrapper {
                height: 100%;
                flex: 1;

                .form-scout {
                    height: 100%;

                    button {
                        margin: 0 !important;
                        width: 100%;
                        height: 100%;
                        border-radius: 0;
                    }
                }
            }
        }

        .navbar-nav {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;

            .nav-item {
                flex: 0 0 100%;
                width: 100%;

                .nav-link {
                    padding: ( $spacer * .75 ) 0;

                    button {
                        text-align: left;
                    }

                    i {
                        min-width: $spacer * 1.25;
                    }
                }

                &.active {

                    .nav-link {
                        color: $white;
                        opacity: 1;
                    }
                }

                &.dropdown {

                    > .nav-link {

                        &:after {
                            content: '';
                            display: inline-flex;
                            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' id='Layer_2' viewBox='0 0 18 10.01'%3E%3Cg%3E%3Cpath fill='%23ffffff' d='m8.29,9.71c.39.39,1.03.39,1.41,0L17.71,1.71c.39-.39.39-1.03,0-1.41s-1.03-.39-1.41,0l-7.29,7.29L1.7.29C1.31-.1.68-.1.29.29S-.1,1.32.29,1.71l8,8Z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
                            background-size: contain;
                            width: 12px;
                            height: 7px;
                            position: absolute;
                            top: 1.2rem;
                            right: 0;
                            transition: transform .3s ease;
                        }

                        &:not(.collapsed) {

                            &:after {
                                transform: rotate(180deg);
                            }
                        }
                    }

                    &.active {
                        .nav-item {
                            &.active {
                                .nav-link {
                                    opacity: 1;
                                }
                            }
                        }
                    }

                    ul {
                        list-style: none;
                        padding-left: $spacer * 2;

                        .nav-link {
                            padding-top: $spacer * .25;
                            padding-bottom: $spacer * .25;
                            opacity: .75;
                            transition: opacity .3s ease;

                            &:hover {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }

        .offcanvas {
            overflow-y: auto;
        }
    }
}
