/*----- DATEPICKER -----*/
.datepicker:not(.datepicker-dropdown), .table-condensed {
    width: 100%;
}

.datepicker table tr td.active,
.datepicker table tr td.active:hover {
    background-color: #3d8df5!important;
}

.datepicker table tr td.today,
.datepicker table tr td.today:hover {
    background-color: #eee;
    border-color: #eee;
}

/*----- DROPDOWNS -----*/
.dropdown-menu {
    background-color: #fff;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: #0c1b32;
    color: #fff;
}

.dropdown-toggle:after {
    vertical-align: middle;
}

/*----- FADER -----*/
.fader {
    position: absolute;
    top: 0;
    width: 5%;
    height: 100%;
    z-index: 1;
    pointer-events: none;
}

.fader:first-child {
    left: 0;
    background-image: linear-gradient(to right, white 0%, transparent 100%);
}

.fader:last-child {
    right: 0;
    background-image: linear-gradient(to left, white 0%, transparent 100%);
}

/*----- GRADIENT -----*/
.btn-gradient,
.btn-gradient:hover {
    color: #fff!important;
    background-color: blue!important; /* For browsers that do not support gradients */
    background-image: linear-gradient(to left, #0092ff, #0021a6, #00027e)!important;
}

