// Fonts
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:400,700&display=swap);

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

// Colors
@import 'colors';

// Bootstrap
@import 'bootstrap/scss/bootstrap';

// General styles
@import 'general';

// Components
@import 'alert';
@import 'forms';
@import 'pagination';
@import 'tables';
@import 'card';
@import 'select2';
@import 'progress';
@import 'tooltips';
@import 'navbar';
@import 'nav-tabs';
@import 'nav-pills';

// Modules
@import 'agenda';
@import 'team';
@import 'notifications';

// Custom
@import 'custom';
