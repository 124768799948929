/*----- DATEPICKER -----*/
.datepicker:not(.datepicker-dropdown), .table-condensed {
    width: 100%;
}

.datepicker table tr td.active,
.datepicker table tr td.active:hover {
    background-color: #3d8df5!important;
}

.datepicker table tr td.today,
.datepicker table tr td.today:hover {
    background-color: #eee;
    border-color: #eee;
}

/*----- FULLCALENDAR -----*/
.fc.fc-theme-bootstrap5 th,
.fc.fc-theme-bootstrap5 td {
    border: 0;
}

.fc .fc-non-business {
    background-color: rgba(0, 0, 0, 0.05);
}

.fc.loading .fc-view {
    -webkit-mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
    mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
    -webkit-mask-size: 200% 100%;
    mask-size: 200% 100%;
    animation: placeholder-wave 2s linear infinite;
}

.fc.loading td.fc-day,
.fc.loading .fc-list {
    opacity: .05;
    background-color: currentcolor!important;
}

.fc.loading td.fc-day > *,
.fc.loading .fc-list > * {
    opacity: 0;
}

.fc td.fc-day.fc-day-today,
.fc td.fc-day .fc-highlight {
    background-color: rgba(var(--teamdesq-primary-rgb), .05);
}

.fc[data-calendar-view="timeGridDay"] td.fc-day.fc-day-today {
    background-color: transparent;
}

/*----- NAVIGATION -----*/
/*.navbar {*/
/*    padding: .25rem 0;*/
/*    background-color: #fff;*/
/*    border-bottom: 1px solid #dee2e6;*/
/*}*/

/*.navbar:nth-last-child(1 of .navbar) {*/
/*    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);*/
/*}*/

/*.navbar .nav-item {*/
/*    padding: 0 .75rem;*/
/*}*/

/*.navbar-collapse form > * {*/
/*    width: 100%;*/
/*}*/

/*.navbar-collapse .nav-item {*/
/*    padding: 0;*/
/*    opacity: .5;*/
/*}*/

/*.navbar-collapse .nav-item:hover,*/
/*.navbar-collapse .nav-item.active {*/
/*    opacity: 1;*/
/*}*/

/*----- DROPDOWNS -----*/
.dropdown-menu {
    background-color: #fff;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: #0c1b32;
    color: #fff;
}

.dropdown-toggle:after {
    vertical-align: middle;
}

/*----- FADER -----*/
.fader {
    position: absolute;
    top: 0;
    width: 5%;
    height: 100%;
    z-index: 1;
    pointer-events: none;
}

.fader:first-child {
    left: 0;
    background-image: linear-gradient(to right, white 0%, transparent 100%);
}

.fader:last-child {
    right: 0;
    background-image: linear-gradient(to left, white 0%, transparent 100%);
}

/*----- GRADIENT -----*/
.btn-gradient,
.btn-gradient:hover {
    color: #fff!important;
    background-color: blue!important; /* For browsers that do not support gradients */
    background-image: linear-gradient(to left, #0092ff, #0021a6, #00027e)!important;
}

