.fullcalendar {

    &.loading {

        .fc-view {
            -webkit-mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
            mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
            -webkit-mask-size: 200% 100%;
            mask-size: 200% 100%;
            animation: placeholder-wave 2s linear infinite;
        }

        td.fc-day,
        .fc-list {
            opacity: .05;
            background-color: currentcolor !important;

            > * {
                opacity: 0;
            }
        }
    }

    &.fc-theme-bootstrap5 {

        .fc-toolbar {
            flex-wrap: wrap;
            gap: $spacer * 0.75;

            > .fc-toolbar-chunk {

                &:nth-of-type(2) {

                    @include media-breakpoint-down(md) {
                        order: 3;
                        flex: 0 0 100%;
                        text-align: center;
                    }
                }
            }
        }

        .fc-toolbar-title {
            font-size: $h4-font-size;
        }

        table {

            .fc-col-header {

                a {
                    text-decoration: none;
                }
            }

            th {
                border: 0;
            }

            th.fc-scrollgrid-shrink a,
            a.fc-daygrid-week-number {
                background-color: $secondary;
                color: $white;
                border-radius: 3px;
                padding: .15rem .25rem;
                text-decoration: none;
                font-weight: 700;
            }

            td {
                border: 0;

                &.fc-day {

                    &.fc-day-today {
                        background-color: rgba(var(--teamdesq-primary-rgb), .05);
                    }

                    &.fc-highlight {
                        background-color: rgba(var(--teamdesq-primary-rgb), .05);
                    }

                    .fc-daygrid-event-harness-abs {
                        padding-bottom: 2px;
                    }

                    .fc-daygrid-event {
                        margin-right: 0;
                        margin-left: 0;
                        border-radius: 3px;
                        padding: 0 5px;
                    }
                }
            }

            .fc-non-business {
                background-color: rgba(0, 0, 0, 0.05);
            }
        }

        &[data-calendar-view="timeGridWeek"] {

            .fc-view-harness {
                width: 100%;
                overflow-x: auto;

                table {
                    min-width: 630px;
                }
            }
        }

        &[data-calendar-view="timeGridDay"] {

            > div > .fc-view > table {
                padding: $spacer;
            }

            table {

                .fc-col-header {

                    .fc-day-today {
                        text-align: right;
                    }
                }

                td {

                    &.fc-day {

                        &.fc-day-today {
                            background-color: transparent;
                        }
                    }
                }

                .fc-daygrid-day-events {
                    min-height: 0;
                    margin-bottom: 0;
                }
            }
        }
    }
}

.card {

    .card-body {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        > .fullcalendar {
            min-height: 100%;
            width: 100%;
            flex: 1;
        }
    }
}
