div[role="progressbar"]:not(.progress) {
    --percentage: var(--value);
    width: 2.5rem;
    height: 2.5rem;
    animation: progress 1s ease-in-out;
}

div[role="progressbar"]:not(.progress).primary {
    background: radial-gradient(closest-side, white 80%, transparent 0 100%, white 0), conic-gradient(#3d8df5 calc(var(--percentage) * 1%), #f1f2f4 0);
}

div[role="progressbar"]:not(.progress).secondary {
    background: radial-gradient(closest-side, white 80%, transparent 0 100%, white 0), conic-gradient(#8c94a1 calc(var(--percentage) * 1%), #f1f2f4 0);
}

div[role="progressbar"]:not(.progress).success {
    background: radial-gradient(closest-side, white 80%, transparent 0 100%, white 0), conic-gradient(#29b000 calc(var(--percentage) * 1%), #f1f2f4 0);
}

@keyframes progress {
    0% {--percentage: 0;}
    100% {--percentage: var(--value);}
}

@property --percentage {
    syntax: '<number>';
    inherits: false;
    initial-value: 0;
}
