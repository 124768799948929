/* ---------------- */
/* ----- ROOT ----- */
/* ---------------- */

html {
    min-width: 100%;
    min-height: 100%;
    background: $white;
    box-sizing: border-box;
    scroll-behavior: smooth;
    overflow-x: hidden;
}

*, *:before, *:after {
    box-sizing: inherit;
}

*:focus {
    outline: 0;
    box-shadow: none !important;
}

::-webkit-scrollbar {
    height: 4px;
    width: 4px;
}

::-webkit-scrollbar-track {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0;
    background-position: -4px;
    background-size: 4px;
    background-color: $light;
}

::-webkit-scrollbar-thumb {
    background-color: $primary;
    border-radius: 4px;
    background-size: 4px;
    transition: all .3s ease;
}

::-webkit-scrollbar-thumb:window-inactive {
    background-color: transparent;
}

body {
    color: #0c1b32;
    font-size: .9rem;
    line-height: 1.5;
    font-family: "Nunito Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    padding-right: 0 !important; // fix for offcanvas
}

::placeholder {
    opacity: .5!important;
}

/* ------------------- */
/* ----- LOADING ----- */
/* ------------------- */

#loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
    background: #f8f9fa;
}

/* ---------------- */
/* ----- LOGO ----- */
/* ---------------- */

div.logo {
    position: relative;
    width: 50px;
    height: 50px;
    margin: auto;

    @include media-breakpoint-up(lg) {
        width: 72px;
        height: 72px;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: 50%;
        background-color: blue; /* For browsers that do not support gradients */
        background-image: conic-gradient(from 45deg, #0092ff, #0021a6, #00027e);
    }

    &:after {
        content: '';
        position: absolute;
        top: calc(100% / 6);
        left: calc(100% / 6);
        height: calc(100% / 1.5);
        width: calc(100% / 1.5);
        border-radius: 50%;
        background-image: conic-gradient(from 310deg, #ffffff, transparent 100%, transparent 0%);
    }

    &.white {
        &:before {
            background-color: transparent; /* For browsers that do not support gradients */
            background-image: conic-gradient(from 45deg, #ffffff, transparent 100%, transparent 0%);
        }
    }
}

/* ------------------ */
/* ----- BADGES ----- */
/* ------------------ */

.badge {
    vertical-align: middle;
    line-height: 1.5;
}

/* ------------------- */
/* ----- CONTENT ----- */
/* ------------------- */

.main-content {
    overflow-x: hidden;

    @include media-breakpoint-up(lg) {
        padding-left: 260px;
    }
}
