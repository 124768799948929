/* GLOBAL */
$variable-prefix: 'teamdesq-';

/* Body */
$body-bg: #fff;

/* SPACERS */
$spacer: 1rem;
$spacers: (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3),
    6: ($spacer * 4.5),
    7: ($spacer * 6)
);

/* Typography */
$font-family-sans-serif: "Nunito Sans", sans-serif;
$font-size-base: .9rem;
$line-height-base: 1.6;
$paragraph-margin-bottom: $spacer * 1.5;
$hr-margin-y: $spacer * 1.5;

$h1-font-size: $font-size-base * 1.75;
$h2-font-size: $font-size-base * 1.6;
$h3-font-size: $font-size-base * 1.3;
$h4-font-size: $font-size-base * 1.1;
$h5-font-size: $font-size-base * .9;
$h6-font-size: $font-size-base * .8;

$headings-margin-bottom: $spacer * 1.5;
$headings-font-weight: 700;

/* COLORS */
$primary:         #0092FF;
$primary-dark:    #0021A6;
$primary-light:   #00CFFF;

$secondary:       #242954;
$secondary-dark:  #0c1b32;

$light:           #f4f4f4;
$dark:            #111111;
$success:         #29b000; // kleur #239600 van Arno is lelijk
$white:           #ffffff;

$custom-colors: (
    'white':            $white,
    'primary-dark':     $primary-dark,
    'primary-light':    $primary-light,
    'secondary-dark':   $secondary-dark,
);

$min-contrast-ratio: 2.87;

/* FONTS */
//$font-primary:   'Open Sans', sans-serif;
//$font-secondary: 'Montserrat', sans-serif;

/* TEXT */
//$body-font-size: 1rem;
//$body-font-size-smaller: .875rem;
//$body-line-height: 1.5;
//$link-color: $dark;
//$link-color-dark: $white;
//$link-decoration: none;

$link-color: $dark;
$link-color-dark: $white;

/* FORMS */
//$input-padding-y: 1rem;
//$input-padding-x: 1.5rem;
//$input-font-size: .9375rem;

/* BUTTONS */
//$btn-padding-y-sm: .625rem;
//$btn-padding-x-sm: 1rem;
//$btn-font-size-sm: .875rem;

$btn-border-radius: 2rem;
$btn-border-radius-sm: 2rem;
$btn-border-radius-lg: 2rem;
$btn-border-width: 1px;

$input-btn-padding-y: .65rem;
$input-btn-padding-x: 1.25rem;
$input-btn-font-size: .8rem;

$input-btn-padding-y-sm: .25rem;
$input-btn-padding-x-sm: .75rem;
$input-btn-font-size-sm: .7rem;

/* BADGES */
//$badge-padding-y: .625rem;
//$badge-padding-x: 1rem;
//$badge-font-size: .875rem;

$badge-border-radius: .25rem;

/* ALERTS */
$alert-border-left-width: 5px;

/* BREAKPOINTS */
//$grid-breakpoints: (
//        xs: 0,
//        sm: 576px,
//        md: 768px,
//        lg: 992px,
//        xl: 1200px,
//        xxl: 1400px,
//        xxxl: 1600px
//);

/* CONTAINER */
//$container-max-widths: (
//        sm: 540px,
//        md: 720px,
//        lg: 960px,
//        xl: 1140px,
//        xxl: 1320px,
//        xxxl: 1440px
//);

/* PAGINATION */
$pagination-padding-y: .45rem;
$pagination-padding-x: .6rem;
$pagination-active-bg: $secondary;
$pagination-active-border-color: $secondary;

/* TABLES */
$table-cell-padding-y: $spacer * 1.5;
$table-cell-padding-x: $spacer * 1.5;

/* CARDS */
$card-spacer-y: $spacer * 1.5;
$card-spacer-x: $spacer * 1.5;
$card-cap-padding-y: $card-spacer-y;
$card-cap-padding-x: $card-spacer-x;
$card-title-spacer-y: 0;
$card-subtitle-color: $secondary;
$card-bg: $white;
$card-border-color: $light;

/* NAVBAR */
$navbar-padding-y: 0;
$navbar-toggler-border-radius: 0;
$navbar-brand-font-size: $font-size-base;
$navbar-light-toggler-icon-bg: none;
$navbar-dark-toggler-icon-bg: none;
$navbar-light-toggler-border-color: 0;
$navbar-dark-toggler-border-color: 0;
$navbar-dark-icon-color: $white;

/* POPOVER */
$popover-inner-border-radius: .25rem;
$popover-header-bg: $primary;
$popover-header-color: $white;
$popover-header-font-size: $h5-font-size;
$popover-body-font-size: $h5-font-size;
$popover-border-color: $primary;
