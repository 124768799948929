.nav-tabs {
    margin-bottom: 1.5rem;
}

.nav-tabs .nav-link,
.nav-tabs .nav-link:hover {
    font-weight: 700;
    color: #0c1b32!important;
    border: none;
    border-radius: 0;
    border-color: #3d8df5;
    border-bottom: 2px solid transparent;
    white-space: nowrap;
}

.nav-tabs .nav-link.active {
    background-color: transparent;
    border-bottom: 2px solid #3d8df5;
}

.nav-tabs.week .nav-link.active {
    color: #fff!important;
    background-color: #3d8df5!important;
}

.nav-tabs.week .nav-link.today {
    background-color: #f1f2f4;
    border-bottom: 2px solid #3d8df5;
}
