.form-label {
    margin-bottom: .75rem;
    font-weight: 700;
}

.form-label.required:after {
    content: "*";
    color: red;
}

.form-control {
    color: #0c1b32;
    box-shadow: none;
    border-radius: 5px;
    border: 1px solid #dee2e6;
    padding: .5rem 1rem;
    background-color: transparent;
}

.form-control:focus {
    background-color: transparent;
    border-color: #0c1b32;
}

.form-switch .form-check-input {
    border: none;
    width: 2.5rem;
    height: 1.25rem;
    margin-top: 0;
    margin-right: 1rem;
    background-color: #dee2e6;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28 255, 255, 255, 1%29'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
    background-color: #3d8df5;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28 255, 255, 255, 1%29'/%3e%3c/svg%3e");
}

.form-switch .form-check-label {
    color: #8c94a1;
}

.form-switch .form-check-input:checked + .form-check-label {
    color: #3d8df5;
}

.input-group-text {
    background-color: #dee2e6;
}

#search, .form-scout input {
    padding: 0;
    border: none;
    line-height: 1;
    background-color: transparent;
    color: inherit;
}
