.pagination-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    padding: $spacer * .75;
    gap: $spacer * .75;

    @include media-breakpoint-up(lg) {
        padding: $spacer * 1.5;
    }

    .pagination-select {
        margin-right: $spacer * .75;

        @include media-breakpoint-down(lg) {
            padding: .5rem 2rem .5rem 1rem;
        }
    }

    .pagination {
        margin-bottom: 0;

        .page-item {

            &.disabled {
                display: none;

                + .active {

                    .page-link {
                        border-top-left-radius: $pagination-border-radius;
                        border-bottom-left-radius: $pagination-border-radius;
                    }
                }
            }

            .page-link {

                @include media-breakpoint-up(lg) {
                    padding: .75rem;
                }
            }
        }

        &:has(> li:last-child.disabled) {

            > li:nth-last-child(2).active {

                .page-link {
                    border-top-right-radius: $pagination-border-radius;
                    border-bottom-right-radius: $pagination-border-radius;
                }
            }
        }
    }
}
